//
// Card
//

.card.card-docs {
    background-color: $body-bg;
    border: 0;
}

.k-grid {
    .custom-date-filter {
        .k-button-icon.k-icon.k-i-calendar {
            display: none;
        }
    }

    .k-filtercell-operator,
    .k-filtercell>.k-button {
        display: NONE;
    }
}