//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
.personal-bank-details .k-animation-container,
.my-profile .k-animation-container {
    width: 100%;
}

.k-dialog-wrapper {
    z-index: 99 !important;
}